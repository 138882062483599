/** @format */

import { cloneDeep, unionBy } from "lodash";
import * as a from "actiontypes";
import { sortByDate } from "util/time";

export const chatroomsInitial = initialRooms => {
	let rooms = cloneDeep(initialRooms);
	for (let room of rooms) {
		room.chat_history = [];
		room.chatters = 0;
	}
	return rooms;
};

const chatrooms = (state = [], action) => {
	switch (action.type) {
		case "WEBSOCKET::POPULATE_CONFIGURATIONS":
			return chatroomsInitial(action.payload.rooms);

		case a.WS_MESSAGE_RECEIVED: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			if (room) {
				let newMessage = action.payload;
				room.chat_history.push(newMessage);
			}
			return newState;
		}

		case a.WS_MESSAGE_RECEIVED_BATCH: {
			let newState = cloneDeep(state);
			// Same logic as WS_MESSAGE_RECEIVED but looped
			for (const payload of action.payload) {
				let room = newState.find(room => room.room_id === payload.room_id);
				if (room) {
					let newMessage = payload;
					room.chat_history.push(newMessage);
				}
			}
			return newState;
		}

		case a.WS_REQUEST_CHAT_HISTORY: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			room["fetching_more_history"] = true;
			return newState;
		}
		case a.WS_UPDATE_CHAT_HISTORY: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			if (action.payload.skip > 0) {
				let updated_history = action.payload.history;
				for (let msg of room["chat_history"]) {
					updated_history.push(msg);
				}
				room["chat_history"] = updated_history;
			} else {
				room["chat_history"] = action.payload.history;
			}
			room["more"] = action.payload.more;
			room["fetching_more_history"] = false;
			return newState;
		}

		// FIXME: better handling for joining rooms not defined in original config
		case a.WS_ROOM_JOINED: {
			let newState = cloneDeep(state);

			if (!action.payload.is_private_chat) {
				let room = newState.find(room => room.room_id === action.payload.room_id);
				if (room) {
					room.room_name = action.payload.room_name;
					room.translated_room_name = action.payload.translated_room_name;
					room.topic = action.payload.topic;
					room.translated_topic = action.payload.translated_topic;
					room.chat_history = action.payload.chat_history;
					room.more = action.payload.more;
				} else {
					newState.push({
						room_id: action.payload.room_id,
						room_name: action.payload.room_name,
						translated_room_name: action.payload.translated_room_name,
						topic: action.payload.topic,
						translated_topic: action.payload.translated_topic,
						chat_history: action.payload.chat_history,
						more: action.payload.more
					});
				}
			}

			return newState;
		}

		case a.WS_USER_JOINED_ROOM: {
			let message = {
				sender: "SYSTEM",
				message: "user joined room"
			};
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			// TODO: these seem to be sent for any user joining any room, not just the ones we're in
			if (!room) return newState;
			room.chat_history.push(message);
			return newState;
		}

		case a.WS_ALL_USER_MESSAGES_DELETED: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			room.chat_history = room.chat_history.filter(msg => msg.user_id !== action.payload.user_id);
			return newState;
		}
		case a.WS_MESSAGE_DELETED: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			room.chat_history = room.chat_history.filter(msg => msg.message_id !== action.payload.message_id);
			return newState;
		}
		case a.WS_HELD_MESSAGE_DELETED: {
			let newState = cloneDeep(state);
			let room = newState.find(room => room.room_id === action.payload.room_id);
			room.chat_history = room.chat_history.filter(msg => msg.message_id !== action.payload.message_id);
			return newState;
		}

		case a.WS_ANNOUNCE_SILENCE: {
			let newState = cloneDeep(state);
			for (let room of newState) {
				room.chat_history.push({
					sender: "MODERATOR",
					sender_name: "MODERATOR",
					bgColor: "#000",
					type: "SILENCE",
					reason: action.payload.reason,
					time: action.payload.time
				});
			}
			return newState;
		}

		case a.WS_MESSAGE_REACTION: {
			let newState = cloneDeep(state);
			let room_index = newState.findIndex(room => room.room_id === action.payload.room_id);
			let message_index = newState[room_index].chat_history.findIndex(
				msg => msg.message_id === action.payload.message_id
			);
			// Without the chat_history check, caused some users chat to crash while they was still initializing the chat when some other user was reacting to message.
			if (newState[room_index].chat_history) {
				if (newState[room_index].chat_history[message_index].reactions === undefined) {
					newState[room_index].chat_history[message_index].reactions = { [action.payload.reaction]: 1 };
				} else if (action.payload.reaction in newState[room_index].chat_history[message_index].reactions) {
					newState[room_index].chat_history[message_index].reactions[action.payload.reaction] +=
						action.payload.amount;
				} else {
					newState[room_index].chat_history[message_index].reactions[action.payload.reaction] =
						action.payload.amount;
				}
			}

			return newState;
		}

		case a.SET_OWN_HELD_MESSAGES: {
			const { held_messages, room_id } = action.payload;
			let newState = cloneDeep(state);
			let findRoom = newState.find(room => room.room_id === room_id);
			const historyWithHeldMessages = sortByDate(
				unionBy(findRoom.chat_history, held_messages, "message_id"),
				"timestamp"
			);
			findRoom.chat_history = historyWithHeldMessages;
			return newState;
		}

		case a.SET_CHATROOM_RESTRICTED: {
			const { restricted, room_id } = action.payload;
			let newState = cloneDeep(state);
			let findRoom = newState.find(room => room.room_id === room_id);
			findRoom.restricted = restricted;
			return newState;
		}

		default:
			return [...state];
	}
};
export default chatrooms;
