/** @format */

import React, { useContext, useState } from "react";
import * as a from "actiontypes";
import { Modal } from "react-bootstrap";
import { COMPONENT_CONFIGS, newComponents, newElement } from "../constants";
import cloneDeep from "lodash/cloneDeep";
import { IconArrowLeftBack, IconChevronDown } from "components/icons";
import { connect, useSelector } from "react-redux";
import { SettingContext } from "../contexts";
import { useDispatch } from "react-redux";
import { makeSubstring } from "./helpers/mainToolbarHelpers";
import { Select } from "@mantine/core";
import { useGenerateMultilingualData } from "../performers/helpers";

const mapDispatchToProps = dispatch => ({
	handleShowModal: payload => dispatch({ type: a.SET_SHOW_ADD_MODAL, payload })
});

const AddModal = props => {
	const [content] = useState(null);
	const {
		showModal,
		organizer,
		slug,
		handleShowModal,
		selected,
		room,
		editLocks,
		editorDispatch,
		type,
		languages
	} = props;
	const [activePage, setActivePage] = useState("page-1");
	const [page2content, setPage2Content] = useState("");
	const [modalDesc, setModalDesc] = useState("Add new component");
	const [headerTitle, setHeaderTitle] = useState(type === "component" ? "Create new component" : "Add new element");
	const settingContext = useContext(SettingContext);
	const { setActiveItem, view } = settingContext;
	const dispatch = useDispatch();

	const close = () => {
		handleShowModal(false);
	};

	const createElement = selectedElement => {
		let createdElement;
		const newRoom = cloneDeep(room);
		const parentId = selected.parentId;
		for (let view of editLocks) {
			const parentComponent = newRoom.components[view].find(c => c.i === parentId);
			if (parentComponent) {
				createdElement = newElement(parentComponent.elements, parentId, selectedElement);
				parentComponent.elements.push(createdElement);
			}
		}

		editorDispatch({
			type: "EDITOR_NEW_ELEMENT",
			payload: {
				room: newRoom,
				newItem: createdElement
			}
		});
		editorDispatch({
			type: "PUSH_TO_HISTORY",
			payload: {
				room: newRoom,
				label: { text: `Created new element: "${createdElement.name}"`, time: new Date().getTime() }
			}
		});
		dispatch({ type: a.HANDLE_DEFAULT_WIDGET, payload: { name: createdElement.renderType, show: true } });
		handleClosemodal();
	};
	// TODO: add the new component to all views in here, so you can use the automatic component select the same way as automatic element select
	const createComponent = (additional, preset, initialContent) => {
		const newRoom = cloneDeep(room);
		const { modifiedComponents, newComponentId } = newComponents(
			selected.parentId,
			newRoom.components,
			"",
			preset,
			content,
			additional,
			editLocks,
			initialContent,
			languages
		);
		newRoom.components = modifiedComponents;
		let freshComponent = newRoom.components[view].find(c => c.i === newComponentId);
		if (!freshComponent) {
			for (let v of editLocks) {
				const newCmp = newRoom.components[v].find(c => c.i === newComponentId);
				if (newCmp) {
					freshComponent = newCmp;
					break;
				}
			}
		}
		editorDispatch({
			type: "EDITOR_NEW_COMPONENT",
			payload: newRoom
		});
		editorDispatch({
			type: "PUSH_TO_HISTORY",
			payload: {
				room: newRoom,
				label: { text: `Created new component: "${freshComponent?.name}"`, time: new Date().getTime() }
			}
		});
		setActiveItem(freshComponent);
		dispatch({ type: "SET_ACTIVE_COMPONENT_REF", payload: freshComponent.i });
		if (preset !== "blank") {
			handleClosemodal();
		}
	};

	const createComponentCards = () => {
		let componentCards = [];
		let cardComponents = [];
		for (const prop in COMPONENT_CONFIGS) {
			if (
				COMPONENT_CONFIGS[prop].category &&
				!componentCards.find(a => a.category === COMPONENT_CONFIGS[prop].category)
			) {
				componentCards = [
					...componentCards,
					{
						category: COMPONENT_CONFIGS[prop].category,
						description: COMPONENT_CONFIGS[prop].categoryDescription
					}
				];
			}
			if (COMPONENT_CONFIGS[prop].category === "card") {
				cardComponents = [
					...cardComponents,
					{ card: prop, description: COMPONENT_CONFIGS[prop].component.description }
				];
			}
		}
		return { componentCards, cardComponents };
	};

	const titleCase = string => {
		return string[0].toUpperCase() + string.slice(1).toLowerCase();
	};

	const createNavCardsTypeCard = comps => {
		return comps?.map(cc => (
			<ComponentCard
				component={cc}
				title={cc.card}
				text={cc.description}
				handleCardClick={() => handleSelectComponent([], cc.card)}
				icon={COMPONENT_CONFIGS[cc.card]?.component?.addModalIcon}
			/>
		));
	};

	const handleCardClick = elem => {
		if (elem === "blank") return handleCreateEmpty();
		if (type === "component") return handleCreateBlankComponentWithElement(elem);
		else return handleAddElementToExistingComponent(elem);
	};

	const handleCreateEmpty = () => {
		try {
			createComponent([], "blank");
			handleClosemodal();
		} catch (error) {
			setModalDesc(error.message);
			console.error(error);
		}
	};

	const createNavCardsElements = comps => {
		return comps.map(elem => (
			<ComponentCard
				text={COMPONENT_CONFIGS[elem].element.description}
				title={elem}
				handleCardClick={() => handleCardClick(elem)}
				icon={COMPONENT_CONFIGS[elem].element.addModalIcon}
			/>
		));
	};

	const handleOnComponentClick = async category => {
		const { component = {} } = COMPONENT_CONFIGS[category === "card" ? "simple card" : category];
		const {
			getAdditionalData,
			addModalComponent,
			description = `Add new "${category}" - component`,
			addModalHeader,
			can_add_elements
		} = component;
		const additionalDataProps = {
			organizer,
			slug
		};
		let data;
		if (getAdditionalData) {
			data = await getAdditionalData(additionalDataProps);
		}
		const componentProps = {
			componentType: category,
			data,
			config: component,
			create: handleSelectComponent,
			createNavCardsElements,
			elems: can_add_elements,
			createComponentCards,
			createNavCardsTypeCard,
			type
		};
		const modalComponent = addModalComponent(componentProps);
		setPage2Content(modalComponent);
		setActivePage("page-2");
		setModalDesc(description);
		if (addModalHeader) setHeaderTitle(addModalHeader);
	};

	const handleSelectComponent = (additional, selectedComponent, initialContent) => {
		createComponent(additional, selectedComponent, initialContent);
		handleClosemodal();
	};

	const handleCreateBlankComponentWithElement = selectedElement => {
		try {
			createComponent([selectedElement], "blank");
			handleClosemodal();
		} catch (error) {
			setModalDesc(error.message);
			console.error(error);
		}
	};

	const handleAddElementToExistingComponent = selectedElement => {
		try {
			createElement(selectedElement);
			handleClosemodal();
		} catch (error) {
			setModalDesc(error.message);
			console.error(error);
		}
	};

	const handleClosemodal = () => {
		setActivePage("page-1");
		close();
	};

	const handleBackClick = () => {
		setActivePage("page-1");
		setModalDesc("Add new component");
		setHeaderTitle("Create new component");
	};

	const ComponentCard = ({ handleCardClick, text, title, icon }) => {
		return (
			<div
				className="component-link-card"
				onClick={handleCardClick}
				onMouseEnter={() => setModalDesc(text)}
				onMouseLeave={() => setModalDesc("")}
			>
				<div className="link-icon">{icon}</div>
				{titleCase(title)}
			</div>
		);
	};

	return (
		<Modal
			centered
			show={showModal}
			onHide={handleClosemodal}
			size="lg"
			contentClassName="addModalOverrides"
			className="add-modal"
			dialogClassName="add-modal-dialog"
		>
			<Modal.Header className="add-modal-header" closeButton>
				<span>{headerTitle}</span>
			</Modal.Header>
			<Modal.Body className={`add-modal-body ${activePage}`}>
				<div className="add-modal-inner-content">
					<div className="nav-links">
						{type === "component" &&
							createComponentCards()?.componentCards?.map(cc => (
								<ComponentCard
									component={cc}
									text={cc.description}
									title={cc.category}
									handleCardClick={() => handleOnComponentClick(cc.category)}
									icon={
										COMPONENT_CONFIGS[cc.category === "card" ? "simple card" : cc.category]
											?.component?.addModalIcon
									}
								/>
							))}
						{type === "element" && createNavCardsElements(selected.allowedElements)}
					</div>
					<div className="add-modal-description">{modalDesc}</div>
				</div>
				<div className="add-modal-inner-content">
					{page2content}
					<div className="add-modal-description">{modalDesc}</div>
					<button className="modal-back-button" onClick={handleBackClick}>
						<IconArrowLeftBack />
						Back
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export const generateEditorLocalizedField = (data = {}, fallback = "", languages = []) => {
	if (data["en"]) return data["en"];
	else if (data[languages[0]]) return data[languages[0]];
	else if (Object.values(data)[0]) return Object.values(data)[0];
	else return fallback;
};

export const ComponentPreview = props => {
	const { data = [], create, config, componentType } = props;
	const [content, setContent] = useState(null);
	const selectContent = value => {
		let newContent = {};
		if (componentType !== "poll") newContent = data.find(m => m.slug === value);
		else newContent = data.find(m => m.id === value);
		setContent(newContent);
	};

	const { languages = [] } = useSelector(state => state.event);

	const generateOptions = () => {
		switch (componentType) {
			case "poll":
				return data.map(d => {
					const { translated_question = {}, question = "" } = d;
					const label = generateEditorLocalizedField(translated_question, question, languages);
					return { label, value: d.slug };
				});
			case "stream":
				return data.map(d => {
					const { display_name = {}, name = "" } = d;
					const label = generateEditorLocalizedField(display_name, name, languages);
					return { label, value: d.slug, group: d.source };
				});
			default:
				return data.map(d => {
					const { display_name = {}, name = "" } = d;
					const label = generateEditorLocalizedField(display_name, name, languages);
					return { label, value: d.slug };
				});
		}
	};

	return (
		<div className="settings-page">
			<div className="icon-wrapper">
				{config.addModalIcon}
				{config.name}
			</div>

			<div className="selector">
				<label className="preview-select-label">Select source</label>
				<Select
					placeholder="Select"
					data={generateOptions()}
					onChange={selectContent}
					classNames={{ input: "select-preset" }}
					zIndex={9999999999999999999}
				/>
				<button
					className="modal-back-button"
					disabled={!content}
					onClick={() => create([], componentType, content)}
				>
					Create
				</button>
			</div>
		</div>
	);
};

export const BlankComponentPreview = props => {
	const { elems, createNavCardsElements, type } = props;
	const filteredElements = type === "element" ? elems.filter(e => e !== "blank") : elems;
	return <div className="nav-links">{createNavCardsElements(filteredElements)}</div>;
};

export const CardComponentPreview = props => {
	const { createComponentCards, createNavCardsTypeCard } = props;
	const { cardComponents } = createComponentCards();
	const cardComponent = createNavCardsTypeCard(cardComponents);
	return <div className="nav-links">{cardComponent}</div>;
};

export const CarouselComponentPreview = props => {
	const { config, create } = props;
	const [slides, setSlides] = useState(1);

	const initialContent = amount => {
		return {
			slides: parseInt(amount),
			settings: {
				autoplay: true,
				speed: 1000,
				autoplaySpeed: 5000,
				vertical: true
			}
		};
	};

	return (
		<div className="settings-page">
			<div className="icon-wrapper">
				{config.addModalIcon}
				Gallery
			</div>

			<div className="selector">
				<label className="preview-select-label">Slides amount: {slides}</label>
				<input
					type="range"
					className="carousel-input"
					min="1"
					max="10"
					value={slides}
					onChange={e => setSlides(e.target.value)}
				></input>
				<button className="modal-back-button" onClick={() => create(slides, "gallery", initialContent(slides))}>
					Create
				</button>
			</div>
		</div>
	);
};

export default connect(null, mapDispatchToProps)(AddModal);
