/** @format */

import { connect } from "react-redux";
import "./chat.scss";
import * as a from "actiontypes";
import Inbox from "./inbox";
import AttendeesContent from "./attendees";
import RoomsContent from "./rooms";
import Engagement from "./engagement/engagement";
import { LivetoLoader } from "components/icons";
import ChatFeed from "./chatfeed";
import { Localized } from "@fluent/react";
import { IconArrowsRight } from "components/icons";
import { useSwipeable } from "react-swipeable";
import { useFetchUserGroups } from "containers/VirtualEvent/apiCalls";
import { useHandleCooldown, useMessageTimeOut, useUpdateNotifications } from "./helpers/hooks";
import { useRef } from "react";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapStateToProps = state => ({
	websocketUser: state.websocketUser,
	connection: state.connection,
	tabview: state.view.view,
	inbox: state.inbox,
	guestuser: state.user.guestuser,
	username: state.user.username,
	chatrooms: state.chatrooms,
	attendees: state.attendees,
	engagement: state.engagement,
	lobbyFeatures: state.lobby.data.features,
	slowmode: state.moderator.slowmode
});

const mapDispatchToProps = dispatch => ({
	changeView: target => dispatch({ type: a.WS_CHANGE_VIEW, payload: target }),
	toggleProfile: payload => dispatch({ type: a.WS_TOGGLE_PROFILE, payload: payload }),
	combineAttendees: data => dispatch({ type: a.COMBINE_ATTENDEES, payload: data }),
	handleRestriction: data => dispatch({ type: a.SET_CHATROOM_RESTRICTED, payload: data })
});

function Chat(props) {
	const {
		websocketUser,
		tabview,
		view,
		inbox,
		connection,
		showChat,
		setShowChat,
		guestuser,
		chatrooms,
		lobbyFeatures,
		slowmode,
		handleRestriction
	} = props;

	const chatRef = useRef(null);
	const chatTextAreaRef = useRef(null);

	const { tabview: current_view = "chat" } = tabview;
	const { connected, active_channel } = connection;
	const { signed_in, user_id, is_moderator } = websocketUser;
	const { pageTheme } = props;
	let chatroom = chatrooms.find(room => room.room_id === active_channel) || {};
	const {
		translated_topic = {},
		topic: fallbackTopic = "",
		translated_room_name = {},
		room_name: fallbackRoomName = "",
		restricted = false
	} = chatroom;

	const { generate } = useGenerateMultilingualData();

	const room_name = generate(translated_room_name, fallbackRoomName);
	const topic = generate(translated_topic, fallbackTopic);

	const { attendees: attendeesFeature = true, engagement: engagementFeature = true } = lobbyFeatures;

	function changeView(target) {
		props.changeView(target);
		props.toggleProfile(null);
	}

	const [cooldown, timeOutTime, timedOut, handleTimeout] = useHandleCooldown(slowmode, guestuser, active_channel);
	const [isTimedOut] = useMessageTimeOut(timedOut, timeOutTime);
	const cooldownTime = { "--message-cooldown": `${cooldown}s`, "--scale": "0" };
	const cooldownProgressBar = (
		<div
			className={`chat-message-timeout-progressbar-wrapper ${isTimedOut ? "scaled" : ""}`}
			style={cooldownTime}
		/>
	);

	const sendProps = {
		isTimedOut,
		handleTimeout,
		timedOut,
		chatTextAreaRef
	};

	const notifications = useUpdateNotifications(inbox, user_id);

	useFetchUserGroups();

	const chatHandlers = useSwipeable({
		onSwipedRight: () => {
			if (view === "tablet" || view === "mobile") setShowChat(false);
		}
	});

	return (
		<div
			id="liveto-chat"
			className={`${pageTheme} ${view} ${showChat ? "show" : ""}`}
			{...chatHandlers}
			ref={chatRef}
		>
			{(view === "mobile" || view === "tablet") && (
				<IconArrowsRight className="swipe-to-close" size="40" onClick={() => setShowChat(false)} />
			)}
			<div className="chat-heading">
				<h2>{room_name}</h2>
				<p>{topic}</p>
			</div>
			<div className="chat-tabs">
				<Localized id="chat-tab-chat">
					<button
						onClick={() => changeView("chat")}
						disabled={!connected}
						className={"chat-tab-button" + (tabview === "chat" ? " active" : "")}
						data-cy="channels"
					>
						{"Chat"}
					</button>
				</Localized>

				<Localized id="chat-tab-rooms">
					<button
						onClick={() => changeView("rooms")}
						disabled={!connected}
						className={"chat-tab-button" + (tabview === "rooms" ? " active" : "")}
						data-cy="channels"
					>
						{"Rooms"}
					</button>
				</Localized>

				{!guestuser && attendeesFeature && (
					<Localized id="chat-tab-attendees">
						<button
							onClick={() => changeView("attendees")}
							disabled={!connected}
							className={"chat-tab-button" + (tabview === "attendees" ? " active" : "")}
							data-cy="channels"
						>
							{"Attendees"}
						</button>
					</Localized>
				)}

				{!guestuser && (
					<Localized
						id="chat-tab-inbox"
						vars={{ messageAmount: notifications > 0 ? "(" + notifications + ")" : "" }}
					>
						<button
							onClick={() => changeView("inbox")}
							disabled={!connected}
							className={"chat-tab-button" + (tabview === "inbox" ? " active" : "")}
							data-cy="channels"
						>
							{"Inbox {$messageAmount}"}
						</button>
					</Localized>
				)}

				{!guestuser && engagementFeature && (
					<Localized id="chat-tab-engagement">
						<button
							onClick={() => changeView("engagement")}
							disabled={!connected}
							className={"chat-tab-button" + (tabview === "engagement" ? " active" : "")}
							data-cy="channels"
						>
							{"Engagement"}
						</button>
					</Localized>
				)}
			</div>
			{(!connected || current_view === "loading") && (
				<div className={`chat-reconnect-screen ${pageTheme}`}>
					<div className="loader-container">
						<LivetoLoader size="50" />
					</div>
					<Localized id="chat-connecting">Connecting chat..</Localized>
					<Localized id="chat-connecting-helptext">
						<small>If this screen persist, please reopen the browser.</small>
					</Localized>
				</div>
			)}
			{signed_in && tabview === "chat" && <ChatFeed sendProps={sendProps} />}
			{signed_in && tabview === "rooms" && <RoomsContent />}
			{signed_in && tabview === "attendees" && <AttendeesContent />}
			{signed_in && tabview === "inbox" && <Inbox />}
			{signed_in && tabview === "engagement" && <Engagement />}
			{cooldownProgressBar}
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
