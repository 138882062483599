/** @format */

import { Localized } from "@fluent/react";
import {
	IconChatBubble,
	IconCheck,
	IconChevronDown,
	IconChevronRight,
	IconReply,
	IconSettings,
	IconText,
	IconTimes,
	IconUser,
	LivetoLoader
} from "components/icons";
import { useEffect, useState } from "react";
import { RoomSelector, Toggleable } from "./moderationComponents";
import { stripFromPunctuations } from "../util/helpers";
import MessageCard from "./MessageCard";
import { GenericTooltip } from "components/ToolTip/Tooltip";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

export const ChatView = props => {
	const {
		chatrooms = [],
		globalSettings = {},
		held_messages = [],
		fetching,
		setSettings,
		displayType = "desktop",
		loading,
		setLoading,
		setSuccess
	} = props;

	const { blocklist_words = [], blocklist_users = [] } = globalSettings;
	const [filteredHeldMessages, setFilteredHeldMessages] = useState([]);

	const { generate } = useGenerateMultilingualData();
	// Creating options for the react-select, filtering out rooms that do not have any held messages
	const options = chatrooms
		.filter(cr => held_messages.map(hm => hm.roomID).includes(cr.room_id))
		.map(c => {
			const { room_name: fallbackRoomName = "", translated_room_name = {} } = c;
			return { label: generate(translated_room_name, fallbackRoomName), value: c.room_id };
		});

	useEffect(() => {
		setFilteredHeldMessages(held_messages);
	}, [held_messages.length]);

	const filter = val => {
		const { value = "" } = val;
		if (!value) {
			setFilteredHeldMessages(held_messages);
			return;
		}
		const filtered = held_messages.filter(msg => msg.roomID === value);
		setFilteredHeldMessages(filtered);
	};

	const roomSelectorProps = {
		defaultValue: null,
		options,
		handleSelect: filter,
		allRooms: true
	};

	const chatModerationProps = {
		setSettings,
		chatrooms,
		held_messages: filteredHeldMessages,
		blocklist_words,
		blocklist_users,
		displayType,
		loading,
		setLoading,
		setSuccess
	};

	return (
		<div className="chat-moderation-content">
			<div className="chatroom-tabs">
				<RoomSelector {...roomSelectorProps} />
			</div>
			{displayType !== "desktop" && <ButtonInfo />}
			<div className="moderation-main-section chat">
				{fetching && (
					<div className="loading">
						<LivetoLoader size="100" />
					</div>
				)}
				{held_messages.length === 0 ? (
					<div className="moderation-info">
						<Localized id="moderation-no-held-messages">No held messages</Localized>
					</div>
				) : (
					<ChatModerationContent {...chatModerationProps} />
				)}
			</div>
		</div>
	);
};

const ButtonInfo = props => {
	return (
		<Toggleable className="info-tooltip" header={<Localized id="moderation-tooltip-info-header">Info</Localized>}>
			<div className="info-content">
				<div className="info-group">
					<span className="msg-card-button blacklist">
						<IconText />
					</span>
					<Localized id="moderation-tooltip-toggle-blocklist">- Toggle "blocklist words" - feature</Localized>
				</div>
				<div className="info-group">
					<span className="msg-card-button blacklist">
						<IconUser />
					</span>
					<Localized id="moderation-tooltip-blocklist-user">- Blocklist user</Localized>
				</div>
				<div className="info-group">
					<span className="msg-card-button blacklist active">
						<IconUser />
					</span>
					<Localized id="moderation-tooltip-remove-from-blocklist">
						- Remove user from the blocklist
					</Localized>
				</div>
				<div className="info-group">
					<span className="msg-card-button confirm">
						<IconCheck />
					</span>
					<Localized id="moderation-tooltip-accept-msg">- Accept message</Localized>
				</div>
				<div className="info-group">
					<span className="msg-card-button decline">
						<IconTimes />
					</span>
					<Localized id="moderation-tooltip-decline-msg">- Decline message</Localized>
				</div>
			</div>
		</Toggleable>
	);
};

export const ButtonWrapper = props => {
	const { displayType, children, text, buttonText = "" } = props;
	if (displayType !== "desktop") return children;
	return (
		<GenericTooltip text={text} hide="100" orientation="bottom">
			<div className="button-wrapper">{children}</div>
		</GenericTooltip>
	);
};

const ChatModerationContent = props => {
	const {
		chatrooms = [],
		blocklist_words,
		held_messages = [],
		blocklist_users = [],
		setSettings,
		displayType,
		loading,
		setLoading,
		setSuccess
	} = props;
	const msgCardProps = {
		blocklist_users,
		setSettings,
		displayType,
		loading,
		setLoading,
		setSuccess,
		chatrooms,
		blocklist_words
	};
	return (
		<div className="chat-messages">
			{held_messages.map(msg => (
				<MessageCard key={`moderation_${msg.payload.message_id}`} msg={msg} {...msgCardProps} />
			))}
		</div>
	);
};

export const MessageSpans = props => {
	const { message = "", blocklist_words = [], displayType } = props;

	const isBlocklisted = val => {
		return blocklist_words.some(
			word => stripFromPunctuations(val.toLowerCase()) === stripFromPunctuations(word.toLowerCase())
		);
	};
	const words = message
		.split(" ")
		.filter(w => w)
		.map((word, i) => {
			// filter(w => w) removes empty strings
			if (isBlocklisted(word))
				return (
					<ButtonWrapper
						key={`message_word_${i}`}
						text={<Localized id="moderation-tooltip-blocklisted-word" />}
						displayType={displayType}
					>
						<div className="message-word triggered">{word}</div>
					</ButtonWrapper>
				);
			return (
				<div key={`message_word_${i}`} className="message-word">
					{word}
				</div>
			);
		});
	return words;
};

export const WordsToButtons = props => {
	const { message = "", wordsToBlacklist = [], addToList } = props;
	const words = message
		.split(" ")
		.map(w => stripFromPunctuations(w))
		.filter(wo => wo !== "");
	const added = word => {
		return wordsToBlacklist.map(w => w.toLowerCase()).includes(word.toLowerCase());
	};
	return (
		<>
			{words.map((word, i) => (
				<div
					key={`splitted_word_${i}`}
					className={`splitted-word ${!added(word) ? "" : "added"}`}
					onClick={() => addToList(word)}
				>
					{word}
				</div>
			))}
		</>
	);
};

export const SideNav = props => {
	const { currentView = "chat", setCurrentView, displayType = "desktop" } = props;
	return (
		<div className="navigation">
			<button
				className={`liveto-side-nav-button ${currentView === "chat" ? "active" : ""}`}
				onClick={() => setCurrentView("chat")}
			>
				{displayType === "desktop" ? <Localized id="chat-tab-chat">Chat</Localized> : <IconChatBubble />}
			</button>
			<button
				className={`liveto-side-nav-button ${currentView === "settings" ? "active" : ""}`}
				onClick={() => setCurrentView("settings")}
			>
				{displayType === "desktop" ? (
					<Localized id="onetoonemeet-call-settings-tooltip">Settings</Localized>
				) : (
					<IconSettings />
				)}
			</button>
		</div>
	);
};

export const ModerationViewHeader = props => {
	const { currentView = "chat", close, loading, success } = props;
	return (
		<div className="moderation-section-header">
			<div className="header-left-section">
				<Localized id={`moderation-${currentView}-header`} />
				{loading && <LivetoLoader size="40" />}
				{success?.isSuccessful === false && success.message}
			</div>
			<button className="close-button" onClick={close}>
				<IconTimes />
			</button>
		</div>
	);
};
