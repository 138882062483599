/** @format */

import { Localized } from "@fluent/react";
import { IconCheck, IconText, IconTimes, IconUser, LivetoLoader } from "components/icons";
import { chatServiceInvoke } from "middlewares/websocket";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getLocaleTime } from "util/time";
import { parseImageLetter } from "../../chat/message";
import { checkLists } from "../util/helpers";
import { ButtonWrapper, MessageSpans, WordsToButtons } from "./chatComponents";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const MessageCard = props => {
	const {
		msg = {},
		blocklist_words = [],
		chatrooms = [],
		blocklist_users = [],
		setSettings,
		displayType,
		loading,
		setLoading,
		setSuccess
	} = props;
	const { payload: msgData = {} } = msg;
	const { timestamp_recv = "", message_id, sender_name, user_id, message, room_id, icon } = msgData;
	const blocklisted = blocklist_users.includes(user_id);
	const [blacklistWord, setBlacklistWord] = useState(false);
	const [wordsToBlacklist, setWordsToBlacklist] = useState(blocklist_words);

	const { generate } = useGenerateMultilingualData();

	const event_slug = useSelector(state => state.event.slug);
	const findRoom = chatrooms.find(c => c.room_id === room_id) || {};
	const { translated_room_name = {}, room_name: fallbackRoomName = "" } = findRoom;
	const roomName = generate(translated_room_name, fallbackRoomName);
	const addToList = word => {
		if (!wordsToBlacklist.includes(word)) {
			setWordsToBlacklist(prev => {
				return [...prev, word];
			});
		} else {
			setWordsToBlacklist(prev => {
				return prev.filter(w => w !== word);
			});
		}
	};
	const approve = async () => {
		try {
			setLoading(true);
			await chatServiceInvoke("modApproveHeldMsg", msg);
			handleInfo();
		} catch (error) {
			handleInfo(error);
			console.error("Message approval error: ", error);
		} finally {
			setLoading(false);
		}
	};
	const decline = async () => {
		try {
			setLoading(true);
			await chatServiceInvoke("modDeleteHeldMsg", msg);
			handleInfo();
		} catch (error) {
			handleInfo(error);
			console.error("Message declination error: ", error);
		} finally {
			setLoading(false);
		}
	};
	const blocklistWords = async () => {
		try {
			setLoading(true);
			const payload = {
				event_slug,
				changes: {
					blocklist_words: wordsToBlacklist
				}
			};
			const response = await chatServiceInvoke("modChangeEventSetting", payload);
			setSettings(response);
			handleInfo();
		} catch (err) {
			console.error(err);
			handleInfo(err);
		} finally {
			setLoading(false);
		}
	};
	const handleInfo = err => {
		if (err)
			setSuccess({
				isSuccessful: false,
				message: (
					<Localized id="moderation-request-info-error" vars={{ err }}>
						Failure
					</Localized>
				)
			});
		else {
			setSuccess({
				isSuccessful: true,
				message: <Localized id="userprofile-save-success">Success!</Localized>
			});
			setTimeout(() => {
				setSuccess(null);
			}, 7000);
		}
	};
	const blockListUser = async () => {
		try {
			setLoading(true);
			const payload = {
				event_slug,
				changes: {
					blocklist_users: blocklisted
						? blocklist_users.filter(b => b !== user_id)
						: [...blocklist_users, user_id]
				}
			};
			const response = await chatServiceInvoke("modChangeEventSetting", payload);
			setSettings(response);
			handleInfo();
		} catch (error) {
			console.error(error);
			handleInfo(error);
		} finally {
			setLoading(false);
		}
	};

	const disableSubmitButton = checkLists(wordsToBlacklist, blocklist_words) || loading;

	return (
		<div className="message-card">
			<div className="msg-data">
				<div className="message-header">
					<div className="user-data">
						<div className="message-icon" style={{ backgroundImage: `url("${icon}")` }}>
							{parseImageLetter(icon, sender_name)}
						</div>
						<div className="username">{sender_name}</div>
					</div>

					<div className="timestamp">{getLocaleTime(timestamp_recv, "DD.MM.YYYY HH:mm")}</div>
				</div>
				<div className="room-name">
					<Localized id="moderation-message-card-room-name">Room: </Localized>
					<b>{roomName}</b>
				</div>
				<div className="message">
					{blacklistWord ? (
						<WordsToButtons wordsToBlacklist={wordsToBlacklist} message={message} addToList={addToList} />
					) : (
						<MessageSpans message={message} blocklist_words={blocklist_words} displayType={displayType} />
					)}
				</div>
			</div>
			<div className="message-footer">
				<div className="buttons">
					<ButtonWrapper
						displayType={displayType}
						text={<Localized id="moderation-tooltip-toggle-blocklist" />}
						children={
							<button
								className={`msg-card-button blacklist ${blacklistWord ? "active" : ""}`}
								onClick={() => setBlacklistWord(!blacklistWord)}
							>
								<IconText />
								{displayType === "desktop" && (
									<span>
										<Localized id="moderation-setting-label-blocklist_words" />
									</span>
								)}
							</button>
						}
						buttonText={<Localized id="moderation-setting-label-blocklist_words" />}
					/>
					<ButtonWrapper
						displayType={displayType}
						text={
							blocklisted ? (
								<Localized id="moderation-tooltip-remove-from-blocklist" />
							) : (
								<Localized id="moderation-tooltip-blocklist-user" />
							)
						}
						children={
							<button
								className={`msg-card-button blacklist ${blocklisted ? "active" : ""}`}
								onClick={blockListUser}
							>
								<IconUser />
								{displayType === "desktop" && (
									<span>
										<Localized id="moderation-setting-label-blocklist_users" />
									</span>
								)}
							</button>
						}
						buttonText={<Localized id="moderation-setting-label-blocklist_users" />}
					/>
					<ButtonWrapper
						displayType={displayType}
						text={<Localized id="moderation-tooltip-accept-msg" />}
						children={
							<button className="msg-card-button confirm" onClick={approve}>
								<IconCheck />
								{displayType === "desktop" && (
									<span>
										<Localized id="moderation-tooltip-approve-label" />
									</span>
								)}
							</button>
						}
						buttonText={<Localized id="moderation-tooltip-approve-label" />}
					/>
					<ButtonWrapper
						displayType={displayType}
						text={<Localized id="moderation-tooltip-decline-msg" />}
						children={
							<button className="msg-card-button decline" onClick={decline}>
								<IconTimes />
								{displayType === "desktop" && (
									<span>
										<Localized id="moderation-tooltip-decline-label" />
									</span>
								)}
							</button>
						}
						buttonText={<Localized id="moderation-tooltip-decline-label" />}
					/>
					{blacklistWord && (
						<ButtonWrapper
							displayType={displayType}
							text={"Submit new word blocklist"}
							children={
								<button
									disabled={disableSubmitButton}
									className="generic-liveto accept confirm blocklist-words"
									onClick={blocklistWords}
								>
									<Localized id="invitation-submit-invitation-button">Submit</Localized>
								</button>
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MessageCard;
