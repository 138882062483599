/** @format */

import { Localized } from "@fluent/react";
import { IconChevronDown, IconChevronRight, IconTimes, LivetoLoader } from "components/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { chatServiceInvoke } from "../../../../../middlewares/websocket";
import { useChangeModerationSettings, useCreateSettings } from "../util/hooks";
import { useLocalization } from "@fluent/react";
import { getLocString, sortArray, sortStringArr } from "../util/helpers";
import { uniqueId } from "lodash";
import { LoadingOverlay } from "@mantine/core";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

export const ModerationSettingsView = props => {
	const { chatrooms, globalSettings = {}, setSettings, displayType = "desktop" } = props;
	const { l10n } = useLocalization();

	const { generate } = useGenerateMultilingualData();

	const options = chatrooms.map(c => {
		const { room_name: fallbackRoomName = "", translated_room_name = {} } = c;
		return { label: generate(translated_room_name, fallbackRoomName), value: c.room_id };
	});
	const [selectedRoom, setSelectedRoom] = useState({
		label: getLocString(l10n, "moderation-room-selector-label-all-rooms"),
		value: null
	});
	const [roomSettings, setRoomSettings] = useState(null);
	const [loading, setLoading] = useState(false);

	// This must be used because chatroom setting data includes a lot of unneeded stuff and chat setting data includes 2 extra settings (lists)
	const settingsKeys = [
		"hold_all_guest_messages",
		"hold_all_messages",
		"hold_by_blocklisted_user",
		"hold_by_blocklisted_word",
		"allow_anonymous",
		"restricted",
		"slowmode"
	];
	const { submitButton, inProgress, modify, error, status } = useChangeModerationSettings(
		{ global: globalSettings, chatroom: roomSettings },
		setSettings,
		setRoomSettings
	);

	useEffect(() => {
		const invoke = async () => {
			try {
				setLoading(true);
				if (selectedRoom.value) {
					const response = await chatServiceInvoke("modGetRoomSettings", { room_id: selectedRoom.value });
					setRoomSettings(response);
				}
			} catch (error) {
				console.error("Error fetching room settings: ", error);
			} finally {
				setLoading(false);
			}
		};
		invoke();
	}, [selectedRoom]);

	const handleSelect = val => {
		setSelectedRoom(val);
	};

	const settingsProps = {
		roomSettings,
		globalSettings,
		options,
		handleSelect,
		settingsKeys,
		modify,
		displayType,
		selectedRoom
	};

	return (
		<div className="moderation-main-section settings">
			<LoadingOverlay visible={loading || inProgress} loader={<LivetoLoader />} />
			{!(selectedRoom.value && roomSettings) && !globalSettings ? (
				<LoadingOverlay visible={true} loader={<LivetoLoader />} />
			) : (
				<SettingsContent {...settingsProps} />
			)}
			<div className="footer">
				<div>
					{error && <div className="error">{error}</div>}
					{status && (
						<div className={status}>
							<Localized id={`userprofile-save-${status}`}>{status}</Localized>
						</div>
					)}
				</div>
				{submitButton}
			</div>
		</div>
	);
};

export const RoomSelector = props => {
	const { l10n } = useLocalization();
	const { options = [], handleSelect, defaultValue, selectorLabel = false, allRooms = false } = props;
	const updatedOptions = allRooms
		? [{ label: getLocString(l10n, "moderation-room-selector-label-all-rooms"), value: null }, ...options]
		: options;
	const defaultVal = allRooms
		? { label: getLocString(l10n, "moderation-room-selector-label-all-rooms"), value: null }
		: defaultValue;
	return (
		<div className="selector-container">
			{selectorLabel && <div className="selector-label">{selectorLabel}</div>}
			<Select
				defaultValue={defaultVal}
				options={updatedOptions}
				isMulti={false}
				className="room-setting-selector"
				menuPortalTarget={document.body}
				styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				onChange={value => handleSelect(value)}
			/>
		</div>
	);
};

const SettingsContent = props => {
	const {
		settingsKeys,
		globalSettings,
		displayType,
		settingType,
		roomSettings,
		options,
		handleSelect,
		modify,
		selectedRoom
	} = props;
	const attendees = useSelector(state => state.all_attendees);
	const settings = useCreateSettings(roomSettings, globalSettings, modify);
	const settingProps = {
		settings,
		options,
		settingsKeys,
		handleSelect,
		roomSettings,
		selectedRoom,
		globalSettings
	};
	return (
		<div className="moderation-settings-content">
			{!globalSettings ? (
				<LivetoLoader />
			) : (
				<>
					{displayType === "desktop" ? (
						<SettingsContentDesktop {...settingProps} />
					) : (
						<SettingsContentMobile {...settingProps} />
					)}
					<BlocklistWords
						settingKey="blocklist_words"
						initialValue={globalSettings["blocklist_words"]}
						modify={modify}
					/>
					<BlocklistUsers
						settingKey="blocklist_users"
						initialValue={globalSettings["blocklist_users"]}
						modify={modify}
						attendees={attendees}
					/>
				</>
			)}
		</div>
	);
};

const SettingsContentDesktop = props => {
	const { settings, settingsKeys, options, roomSettings, globalSettings, handleSelect, selectedRoom } = props;
	return (
		<div className="moderation-general-settings">
			<div className="moderation-single-setting">
				<div className="values">
					<div className="setting-label"></div>

					{(roomSettings || globalSettings) && (
						<div className="setting-value selector">
							<b>
								<Localized id="moderation-setting-label-chatroom">Chat room</Localized>
							</b>
							<RoomSelector
								options={options}
								handleSelect={handleSelect}
								defaultValue={null}
								allRooms={true}
							/>
						</div>
					)}
				</div>
			</div>
			{settings &&
				settingsKeys.map(s => (
					<SingleSetting
						key={`single-moderation-setting-${s}`}
						settingKey={s}
						label={settings[s]?.label}
						roomValue={settings[s]?.roomValue}
						globalValue={settings[s]?.globalValue}
						description={settings[s]?.description}
						selectedRoom={selectedRoom}
					/>
				))}
		</div>
	);
};

const SettingsContentMobile = props => {
	const { settings, settingsKeys, options, handleSelect, selectedRoom } = props;
	return (
		<div className="moderation-general-settings-mobile">
			<Toggleable
				className="chatroom-settings"
				header={<Localized id="moderation-mobile-chatroom-settings-header">Chatroom settings</Localized>}
			>
				<div className="setting-value selector">
					<Localized id="moderation-setting-label-chatroom">
						<div className="selector-label">Chat room</div>
					</Localized>
					<RoomSelector options={options} handleSelect={handleSelect} defaultValue={null} allRooms={true} />
				</div>
				{settings &&
					settingsKeys.map(s => (
						<SingleSetting
							key={`single-moderation-setting-${s}`}
							settingKey={s}
							label={settings[s]?.label}
							roomValue={settings[s]?.roomValue}
							description={settings[s]?.description}
							globalValue={settings[s]?.globalValue}
							selectedRoom={selectedRoom}
						/>
					))}
			</Toggleable>
		</div>
	);
};

export const Toggleable = props => {
	const { className, header, children } = props;
	const [toggle, setToggle] = useState(false);
	return (
		<div className={`toggle ${className}`}>
			<div className="header" onClick={() => setToggle(!toggle)}>
				{header}
				{!toggle ? <IconChevronRight /> : <IconChevronDown />}
			</div>
			{toggle && children}
		</div>
	);
};

const SingleSetting = props => {
	const { label = "", roomValue = null, globalValue = null, description, selectedRoom = {} } = props;
	return (
		<div className="moderation-single-setting">
			<div className="values">
				<div className="setting-label">{label}</div>
				{selectedRoom.value && roomValue ? (
					<div className="setting-value">{roomValue}</div>
				) : (
					<div className="setting-value">{globalValue}</div>
				)}
				{/* {globalValue && <div className="setting-value">{globalValue}</div>}
				{roomValue && <div className="setting-value">{roomValue}</div>} */}
			</div>
			<div className="description">{description}</div>
		</div>
	);
};

const BlocklistWords = props => {
	const { initialValue, settingKey, modify } = props;
	const [changedVal, setChangedVal] = useState(sortStringArr(initialValue));
	const [filtered, setFiltered] = useState(sortStringArr(changedVal));
	const [addWord, setAddWord] = useState("");
	const [filterCond, setFilterCond] = useState("");

	useEffect(() => {
		if (!filterCond) {
			setFiltered(sortStringArr(changedVal));
			return;
		}
		const newFiltered = sortStringArr(changedVal).filter(elem =>
			elem.toLowerCase().includes(filterCond.toLowerCase())
		);
		setFiltered(newFiltered);
	}, [changedVal.length, filterCond]);

	const handleSubmit = (action, word) => {
		let newVal;
		if (action === "add") {
			newVal = sortStringArr([...changedVal, word]);
		} else {
			newVal = sortStringArr(changedVal.filter(w => w !== word));
		}
		setChangedVal(newVal);
		modify("global", settingKey, newVal);
		setAddWord("");
	};

	const disabled = !addWord || changedVal.includes(addWord);

	return (
		<Toggleable
			className="mod-setting-array"
			header={<Localized id={`moderation-list-setting-${settingKey}`}>{settingKey}</Localized>}
		>
			<div className="mod-setting-array-content">
				<div className="inputs">
					<Localized id="moderation-list-setting-filter-blocklist_words" attrs={{ placeholder: true }}>
						<input
							placeholder="Search words"
							className="chat-filter-input moderation-input"
							value={filterCond}
							onChange={e => setFilterCond(e.target.value)}
						/>
					</Localized>
					<Localized id="moderation-list-setting-add-blocklist_words" attrs={{ placeholder: true }}>
						<input
							placeholder="Add words"
							className="chat-filter-input moderation-input"
							value={addWord}
							onChange={e => setAddWord(e.target.value)}
						/>
					</Localized>
					<Localized id="settings-calendar-add-button">
						<button
							disabled={disabled}
							className="generic-liveto accept"
							onClick={() => handleSubmit("add", addWord)}
						>
							Add
						</button>
					</Localized>
				</div>
				<div className="blocklisted-words-container">
					{filtered.length === 0 && !filterCond && (
						<Localized id="moderation-word-list-empty">The word blocklist is empty</Localized>
					)}
					{filtered.length === 0 && filterCond && (
						<Localized id="attendees-no-search-results">No results</Localized>
					)}
					{filtered.map(word => (
						<div className="word-container" key={uniqueId()}>
							{word}{" "}
							<span className="remove-button" onClick={() => handleSubmit("remove", word)}>
								<IconTimes />
							</span>
						</div>
					))}
				</div>
			</div>
		</Toggleable>
	);
};

const BlocklistUsers = props => {
	const { initialValue = [], settingKey, modify, attendees } = props;
	const makeAttendeesWithData = (list, ids) =>
		sortArray(
			list.filter(a => ids.includes(a.user_id)),
			"last_name"
		);
	const [blockedAttendees] = useState(makeAttendeesWithData(attendees, initialValue));
	const [changedVal, setChangedVal] = useState(initialValue);
	const [filtered, setFiltered] = useState(sortArray(blockedAttendees, "last_name"));
	const [addUsers, setAddUsers] = useState(null);
	const [selectValue, setSelectValue] = useState(null);
	const [filterCond, setFilterCond] = useState("");
	const { l10n } = useLocalization();

	const options = attendees
		.filter(a => !changedVal.includes(a.user_id))
		.map(user => {
			const name = `${user.first_name} ${user.last_name}`;
			const company = user.company_name ? `(${user.company_name})` : "";
			const label = `${name} ${company}`;
			return {
				label,
				value: user.user_id
			};
		});

	useEffect(() => {
		const newAttendeeData = makeAttendeesWithData(attendees, changedVal);
		if (!filterCond) {
			setFiltered(newAttendeeData, "last_name");
			return;
		}
		const newFiltered = sortArray(newAttendeeData, "last_name").filter(elem => {
			return (
				elem.first_name.toLowerCase().includes(filterCond.toLowerCase()) ||
				elem.last_name.toLowerCase().includes(filterCond.toLowerCase())
			);
		});
		setFiltered(newFiltered);
	}, [blockedAttendees, changedVal.length, filterCond]);

	const handleSubmit = (action, value) => {
		let newVal;
		if (action === "add") {
			if (!value) newVal = initialValue;
			else newVal = sortStringArr([...changedVal, ...value]);
		} else {
			newVal = sortStringArr(changedVal.filter(w => w !== value));
		}
		setChangedVal(newVal);
		modify("global", settingKey, newVal);
		setSelectValue(null);
	};
	const handleAddUsers = val => {
		setSelectValue(val);
		if (!val) {
			setAddUsers([]);
			return;
		}
		const mappedToID = val.map(v => v.value);
		setAddUsers(mappedToID);
	};

	const disabled = !addUsers || addUsers.every(u => changedVal.includes(u));

	return (
		<Toggleable
			className="mod-setting-array"
			header={<Localized id={`moderation-list-setting-${settingKey}`}>{settingKey}</Localized>}
		>
			<div className="mod-setting-array-content">
				<div className="inputs">
					<Localized id="moderation-list-setting-filter-blocklist_users" attrs={{ placeholder: true }}>
						<input
							placeholder="Search words"
							className="chat-filter-input moderation-input"
							value={filterCond}
							onChange={e => setFilterCond(e.target.value)}
						/>
					</Localized>
					<Select
						value={selectValue}
						options={options}
						isMulti={true}
						className="w-100"
						menuPortalTarget={document.body}
						styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						onChange={value => handleAddUsers(value)}
						placeholder={getLocString(l10n, "moderation-list-setting-add-blocklist_users")}
					/>
					<Localized id="settings-calendar-add-button">
						<button
							disabled={disabled}
							className="generic-liveto accept"
							onClick={() => handleSubmit("add", addUsers)}
						>
							Add
						</button>
					</Localized>
				</div>
				{blockedAttendees && (
					<div className="blocklisted-words-container">
						{filtered.length === 0 && filterCond && (
							<Localized id="attendees-no-search-results">No results</Localized>
						)}
						{filtered.length === 0 && !filterCond && (
							<Localized id="moderation-user-list-empty">The user blocklist is empty</Localized>
						)}
						{filtered.map(user => (
							<div className="word-container" key={uniqueId()}>
								{`${user.first_name} ${user.last_name}`}{" "}
								<span className="remove-button" onClick={() => handleSubmit("remove", user.user_id)}>
									<IconTimes />
								</span>
							</div>
						))}
					</div>
				)}
				<div className="description">
					<Localized id="moderation-setting-description-user-blocklist">
						Guest users are not displayed in blocklist, go to chat moderation panel to manage guest users
					</Localized>
				</div>
			</div>
		</Toggleable>
	);
};
