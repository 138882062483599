/** @format */

import { IconDownload, IconTimes, LivetoLoader } from "components/icons";
import { API_PATH } from "constants.js";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import ReactPlayer from "react-player";
import { AUTH_HEADERS } from "util/api";
import moment from "moment";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Localized, useLocalization } from "@fluent/react";
import { Tooltip } from "@mantine/core";
import shaka from "shaka-player/dist/shaka-player.ui";
import "shaka-player/dist/controls.css";

export function useFetchStream(event_slug, stream_slug, code, query, isCustomStream) {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [data, setData] = useState(null);
	const apiPath = isCustomStream
		? `${API_PATH}videos/${stream_slug}/`
		: `${API_PATH}events/${event_slug}/streams/${stream_slug}/${code ? code + "/" : ""}${query}`;
	async function fetchData() {
		setLoading(true);
		try {
			const response = await fetch(apiPath, {
				method: "GET",
				headers: AUTH_HEADERS,
				body: undefined
			});
			setStatus(response.status);
			const json = await response.json();
			setData(json);
		} catch {
		} finally {
			setLoading(false);
		}
	}
	useEffect(() => {
		if (stream_slug) {
			fetchData();
		} else {
			setData(null);
			setStatus(null);
		}
	}, [event_slug, stream_slug, code, query]);
	return [loading, status, data, fetchData];
}

class AbrFactory extends shaka.abr.SimpleAbrManager {
	constructor(...args) {
		super(...args);
		// console.log('ABR FACTORY', ...args);
	}
	init(callback) {
		super.init(rates => {
			// const {audio, video} = rates;
			// console.log(`SWITCH TO ${video.width}x${video.height}`);
			// const required = audio.bandwidth + video.bandwidth;
			// console.log('BW REQUIRED', required);
			return callback(rates);
		});
		// this.timer = setInterval(() => this.getBandwidthEstimate(), 10000);
	}
	stop() {
		// clearInterval(this.timer);
	}
	segmentDownloaded(deltaTimeMs, numBytes) {
		// FIXME: This is a total hack, CloudFlare seems to take multiple seconds
		// for connection start up which skews the bandwidth calculation majorly
		// and timing-allow-origin doesn't let us use proper request download time
		// for the first three seconds assume at least 90% of it is connection delay
		const connAdjust = Math.ceil(Math.min(3000, deltaTimeMs) * 0.1 + Math.max(1, deltaTimeMs - 3000));
		// console.log('SEGMENT DOWNLOADED', deltaTimeMs, connAdjust, numBytes);
		return super.segmentDownloaded(connAdjust, numBytes);
	}
	getBandwidthEstimate() {
		const bw = super.getBandwidthEstimate();
		// console.log('BW ESTIMATE', bw);
		return bw;
	}
}

const ShakaPlayer = forwardRef(function({ src, config, chromeless, className, autoPlay, ...rest }, ref) {
	const uiContainerRef = useRef(null);
	const videoRef = useRef(null);

	const [player, setPlayer] = useState(null);
	const [ui, setUi] = useState(null);

	// Effect to handle component mount & mount.
	// Not related to the src prop, this hook creates a shaka.Player instance.
	// This should always be the first effect to run.
	useEffect(() => {
		const volume = localStorage.getItem("shakaPlayerVolume") || 1;
		videoRef.current.volume = volume;
		const player = new shaka.Player(videoRef.current);
		setPlayer(player);

		let ui;
		if (!chromeless) {
			const ui = new shaka.ui.Overlay(player, uiContainerRef.current, videoRef.current);
			let conf = { overflowMenuButtons: ["quality", "picture_in_picture", "playback_rate"] };
			ui.configure(conf);
			setUi(ui);
		}

		return () => {
			player.destroy();
			if (ui) {
				ui.destroy();
			}
		};
	}, []);

	// Keep shaka.Player.configure in sync.
	useEffect(() => {
		if (player && config) {
			player.configure(config);
		}
	}, [player, config]);

	// Load the source url when we have one.
	useEffect(() => {
		if (player && src) {
			player.load(src).then(() => {
				if (autoPlay && videoRef.current) videoRef.current.play();
			});
		}
	}, [player, src]);

	// Define a handle for easily referencing Shaka's player & ui API's.
	useImperativeHandle(
		ref,
		() => ({
			get player() {
				return player;
			},
			get ui() {
				return ui;
			},
			get videoElement() {
				return videoRef.current;
			}
		}),
		[player, ui]
	);

	return (
		<div ref={uiContainerRef} className={className}>
			<video
				onVolumeChange={e => localStorage.setItem("shakaPlayerVolume", e.target.volume)}
				ref={videoRef}
				style={{
					maxWidth: "100%",
					width: "100%"
				}}
				{...rest}
			/>
		</div>
	);
});

function CustomPlayer(props) {
	const config = {
		abr: {
			enabled: true,
			defaultBandwidthEstimate: 10485760, // 10 Mbps
			advanced: {
				minTotalBytes: 1048576, // 1MB
				fastHalfLife: 10,
				slowHalfLife: 20
			}
		},
		abrFactory: (...args) => new AbrFactory(...args),
		manifest: {
			defaultPresentationDelay: 30, // FFS
			dash: {
				ignoreMinBufferTime: true,
				ignoreSuggestedPresentationDelay: true
				// manifestPreprocessor: (mpd) => {
				// 	console.log('GOT DASH MANIFEST', mpd);
				// }
			}
		},
		streaming: {
			segmentPrefetchLimit: 5,
			bufferingGoal: 30,
			rebufferingGoal: 10,
			durationBackoff: 5
		}
	};
	return (
		<ShakaPlayer
			config={config}
			src={props.src}
			style={props.style}
			className={props.className}
			loop={props.loop}
			autoPlay={props.autoPlay}
		/>
	);
}

function Stream(props) {
	const { elem, hilight, elementClick, slug, editorMode } = props;
	const { content, properties } = elem;
	const fallbackSettings = { autoPlay: true, loop: false };
	const { loop, autoPlay } = properties?.["videoSettings"] ?? fallbackSettings;
	const query = useAccessKey();
	const [vodSelector, setVodSelector] = useState(false);
	const [vodMenu, setVodMenu] = useState(false);

	const isCustomStream = content.source === "custom";
	const [loading, status, data, fetchData] = useFetchStream(slug, content.slug, null, query, isCustomStream);

	const allow_downloads = data?.allow_downloads;

	useEffect(() => {
		setStreamSrc(createSource());
	}, [data?.status]);

	useEffect(() => {
		if (vodSelector) {
			fetchData();
		}
	}, [vodSelector]);

	const createSource = () => {
		if (isCustomStream && data) {
			if (data.status === "connected" || data.videos.length === 0) {
				// return data.signed_url;
				// TODO: Use the live-inprogress video so that dash rewind works after stream ends?
				if (!data.videos) return data.signed_url;
				const liveVideo = data.videos.find(v => v.status == "live-inprogress");
				return (liveVideo && liveVideo.signed_url) || data.signed_url;
			}
			if (data.videos.length === 1) {
				return data.videos[0].signed_url;
			}
			setVodSelector(true);
			return data.signed_url;
		}
		return null;
	};

	const [streamSrc, setStreamSrc] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState({ type: null, downloadUrl: null, downloadable: false });

	const handleOnEnded = async () => {
		await fetchData();
	};

	const playerConfigs = {
		youtube: {
			playerVars: {
				showinfo: 0,
				controls: 1,
				autoplay: autoPlay && !editorMode ? 1 : 0,
				iv_load_policy: 0,
				modestbranding: 0,
				rel: 0
			}
		},
		vimeo: {
			playerOptions: { autoplay: autoPlay && !editorMode, controls: true }
		},
		cloudflare: {
			autoplay: autoPlay && !editorMode,
			controls: true,
			src: streamSrc,
			onEnded: handleOnEnded,
			primaryColor: "#00ff44"
		}
	};

	const style = {
		backgroundColor: "#131313",
		width: "100%",
		height: "0",
		paddingBottom: "56.25%",
		position: "relative",
		display: "flex",
		alignItems: "start",
		justifyContent: "center",
		zIndex: 2,
		pointerEvents: editorMode ? "none" : ""
	};
	const streamStyle = {
		width: "100%",
		height: "100%",
		position: "absolute",
		backgroundColor: "transparent",
		top: "0",
		left: "0"
	};
	const handleVOdMenu = action => {
		if (action === "show") {
			if (!vodSelector) setVodMenu(true);
			return;
		}
		setVodMenu(false);
		return;
	};
	const vodSelectorProps = {
		videos: data?.videos,
		setVodSelector,
		setStreamSrc,
		setVodMenu,
		allow_downloads,
		setSelectedVideo
	};
	if (loading) {
		return (
			<DarkContainer>
				<LivetoLoader size="50" />
			</DarkContainer>
		);
	}
	if (status) {
		switch (status) {
			case 200: {
				let streamComponent;
				if (data?.source === "bright") {
					streamComponent = (
						<BrightPlayer
							url={data.url}
							streamStyle={streamStyle}
							autoPlay={autoPlay}
							editorMode={editorMode}
						/>
					);
				} else if (isCustomStream) {
					streamComponent = (
						<CustomPlayer
							style={{ width: "100%" }}
							className="video-embed"
							src={`${streamSrc}/manifest/video.mpd`}
							loop={loop}
							autoPlay={autoPlay && !editorMode}
						/>
					);
				} else {
					streamComponent = (
						<ReactPlayer
							style={streamStyle}
							loop={loop}
							className="video-embed"
							url={data?.url}
							config={playerConfigs}
							width="100%"
							height="100%"
						/>
					);
				}
				return (
					<div
						style={style}
						className={`element-hilight-wrapper${hilight ? hilight : ""}`}
						onMouseEnter={() => handleVOdMenu("show")}
						onMouseLeave={() => handleVOdMenu(null)}
					>
						{vodMenu && !vodSelector && isCustomStream && (
							<div className="player-buttons">
								{selectedVideo.type === "video" && selectedVideo.downloadable && (
									<a
										onClick={e => e.stopPropagation()}
										href={selectedVideo.downloadUrl}
										className="show-vod-selector-button"
									>
										<IconDownload />
									</a>
								)}
								<button onClick={() => setVodSelector(true)} className="show-vod-selector-button">
									Videos
								</button>
							</div>
						)}

						{vodSelector && <VodSelector {...vodSelectorProps} />}
						{streamComponent}
					</div>
				);
			}
			case 401:
			case 402:
			case 403:
				return <DarkContainer>You don't have the permission to watch this stream</DarkContainer>;
			case 404:
				return <DarkContainer>Stream not found</DarkContainer>;
			default:
				return <DarkContainer>Something went wrong</DarkContainer>;
		}
	}
	return <DarkContainer>No stream selected</DarkContainer>;
}

export default Stream;

const DarkContainer = props => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				background: "#000",
				color: "#fff",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
		>
			{props.children}
		</div>
	);
};

const BrightPlayer = props => {
	const { url, streamStyle, autoPlay, editorMode } = props;
	return (
		<iframe
			title="bright-stream"
			width="1132"
			height="637"
			style={streamStyle}
			src={`https://events.icareus.com/web/bright/player/embed/webcast?eventId=${url}&videomax=true`}
			frameBorder="0"
			allow={`accelerometer; ${
				autoPlay && !editorMode ? "autoplay;" : ""
			}encrypted-media; gyroscope; picture-in-picture`}
			allowFullScreen
			scrolling="no"
		></iframe>
	);
};

const VodSelector = props => {
	const { videos = [], setStreamSrc, setVodSelector, setVodMenu, allow_downloads, setSelectedVideo } = props;
	const displayType = useSelector(state => state.displayType);

	const handleOnVideoClick = (source, video) => {
		setStreamSrc(source);
		setSelectedVideo(video);
		setVodSelector(false);
	};
	return (
		<div className={`vod-selector-container ${displayType}`}>
			<button
				className="close-button"
				onClick={() => {
					setVodSelector(false);
					setVodMenu(true);
				}}
			>
				<IconTimes />
			</button>
			<div className="vod-selector-container-inner">
				{videos.map(v => (
					<VodCard handleOnVideoClick={handleOnVideoClick} {...v} allow_downloads_global={allow_downloads} />
				))}
			</div>
		</div>
	);
};

const VodCard = props => {
	const {
		signed_url,
		created_at,
		handleOnVideoClick,
		duration,
		allow_downloads_global,
		allow_downloads,
		download_completion,
		type,
		status
	} = props;
	const thumbnail = `${signed_url}/thumbnails/thumbnail.jpg`;
	const { l10n } = useLocalization();

	const allowDownloads =
		(allow_downloads_global || (!allow_downloads_global && allow_downloads)) && download_completion === 100;

	const createDuration = () => {
		if (duration < 0) return l10n.getString("virtual-event-stream-custom-vod-duration-unknown", null, "unknown");
		if (duration / 60 > 60) {
			return moment(duration * 1000).format("HH.mm.ss");
		}
		return moment(duration * 1000).format("mm.ss");
	};
	return (
		<Col
			style={{ backgroundImage: `url(${thumbnail})` }}
			className="vod-card"
			onClick={() =>
				handleOnVideoClick(signed_url, {
					type,
					downloadUrl: `${signed_url}/downloads/default.mp4`,
					downloadable: allowDownloads
				})
			}
		>
			<Col className="vod-card-content">
				<Col className="vod-card-data">
					<div>
						<Localized
							id={`virtual-event-stream-custom-vod-title${status === "live-inprogress" ? "-live" : ""}`}
						>
							Recording
						</Localized>
					</div>
					<div>{moment(created_at).format("DD.MM.YYYY HH:mm")}</div>
					{duration > 0 && (
						<div>
							<Localized
								id="virtual-event-stream-custom-vod-duration"
								vars={{ duration: createDuration() }}
							>
								<>Duration: {createDuration()}</>
							</Localized>
						</div>
					)}
				</Col>
				<div className="footer">
					<div className="live">
						{status === "live-inprogress" && (
							<>
								<span /> LIVE
							</>
						)}
					</div>
					{allowDownloads && (
						<div className="button-container">
							<Tooltip
								label={<Localized id="virtual-event-stream-custom-vod-download-tooltip" />}
								withArrow
							>
								<a onClick={e => e.stopPropagation()} href={`${signed_url}/downloads/default.mp4`}>
									<IconDownload className="download" />
								</a>
							</Tooltip>
						</div>
					)}
				</div>
			</Col>
		</Col>
	);
};
