/** @format */

import { Localized } from "@fluent/react";
import { connect } from "react-redux";
import * as a from "actiontypes";
import { useSelector } from "react-redux";
import { useHandleGroupMembership } from "../../../apiCalls";
import { useEffect, useState } from "react";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapStateToProps = state => ({
	myGroups: state.user.groups,
	requireGroups: state.lobby.data.require_group_join
});

const mapDispatchToProps = dispatch => ({
	reconnectWebsocket: () => dispatch({ type: a.WS_RECONNECT }),
	calendarVisibilityChange: value => dispatch({ type: a.SET_CALENDAR_VISIBILITY, payload: value }),
	groupWarning: () => dispatch({ type: "INIT_GROUPS", payload: false }),
	closeModals: () => dispatch({ type: a.HANDLE_MODALS, payload: true })
});

const UserGroupSettings = props => {
	const {
		myGroups,
		userGroups,
		setSuccess,
		setShowSuccess,
		setShowLoading,
		groupWarning,
		closeModals,
		requireGroups = false
	} = props;
	const slug = useSelector(state => state.event.slug);
	const [action, setAction] = useState(null);
	const [data, setData] = useState(false);
	const myGroupsLen = myGroups.length;
	const [status, error, success, loading, setHandled] = useHandleGroupMembership(action, data, slug);
	const joinableGroups = userGroups.filter(g => g.joinable);

	const { generate } = useGenerateMultilingualData();

	useEffect(() => {
		setShowLoading(loading);
		if (myGroupsLen === 0 && joinableGroups.length > 0 && requireGroups) {
			closeModals();
			groupWarning();
		}
	}, [loading, setShowLoading, myGroupsLen]);

	const handleGroups = async (actionType, groupSlug) => {
		setData(groupSlug);
		setAction(actionType);
		setHandled(true);
		if (error || !success) {
			setSuccess("failure");
		} else {
			setSuccess("success");
			props.reconnectWebsocket();
		}
		setShowSuccess(true);
	};

	return (
		<div className="settings-user-groups-container">
			<div className="heading-row">
				<h5>
					<Localized id="settings-user-groups-header">User group settings</Localized>
				</h5>
			</div>
			<div className="input-row-wrapper">
				<div className="subheading-row">
					<h5>
						<Localized id="settings-user-groups-my-groups-header">My groups</Localized>
					</h5>
					{myGroups.length > 0 ? (
						<div className="user-groups-container">
							{userGroups
								.filter(g => myGroups.includes(g.slug))
								.map(group => {
									const { display_name = {}, name = "" } = group;
									return (
										<div className="user-groups-my-group-row" key={`my_group_${group.slug}`}>
											<div className="name-button">
												<div className="group-name">{generate(display_name, name)}</div>
												<button
													className="content-button"
													onClick={() => handleGroups("leave", group.slug)}
												>
													<Localized id="settings-user-groups-leave-button">Leave</Localized>
												</button>
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<div className="settings-user-groups-no-public-groups">
							<Localized id="settings-user-groups-no-groups">
								You are not a member of any user group, join user groups below
							</Localized>
						</div>
					)}
				</div>
				<div className="subheading-row">
					<h5>
						<Localized id="settings-user-groups-join-header">Join public groups</Localized>
					</h5>
					{userGroups.filter(g => !myGroups.includes(g.slug)).length > 0 ? (
						<div className="user-groups-container">
							{userGroups
								.filter(g => !myGroups.includes(g.slug))
								.map(group => (
									<div className="user-groups-my-group-row" key={`public_group_${group.slug}`}>
										<div className="name-button">
											<div className="group-name">{group.name}</div>
											<button
												className="content-button"
												disabled={group.joinable ? false : true}
												onClick={() => handleGroups("join", group.slug)}
											>
												<Localized id="settings-user-groups-join-button">Join</Localized>
											</button>
										</div>
										{!group.joinable && (
											<div className="group-description">
												<Localized id="settings-user-groups-permission-description">
													This group is private, you need a permission to join
												</Localized>
											</div>
										)}
									</div>
								))}
						</div>
					) : (
						<div className="settings-user-groups-no-public-groups">
							<Localized id="settings-user-groups-no-public-groups">No public groups available</Localized>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupSettings);
