/** @format */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Jitsi from "react-jitsi";
import { AUTH_HEADERS } from "util/api";
import { HOST, MEET_DOMAIN } from "constants.js";
import "./jitsi-overrides.scss";
import ReactLoading from "react-loading";
import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import { LivetoLoader } from "components/icons";
import jitsi_start_background from "static/img/Jitsi_start_background.png";
import { Localized } from "@fluent/react";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

function useFetchMeetInfo(slug, roomSlug) {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const [data, setData] = useState(null);
	const accessKey = useAccessKey();

	useEffect(() => {
		async function fetchMeet() {
			setLoading(true);
			try {
				const response = await fetch(`${HOST}api/v1/events/${slug}/meets/${roomSlug}/${accessKey}`, {
					headers: AUTH_HEADERS
				});
				setStatus(response.status);
				const json = await response.json();

				if (response.ok) {
					setData(json);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
		if (roomSlug) {
			fetchMeet();
		} else {
			setStatus(null);
			setData(null);
			setLoading(false);
		}
	}, [slug, roomSlug]);
	return [loading, status, data];
}

const mapStateToProps = state => {
	// TODO CHECK CORRECT DATA
	return {
		info: state.user,
		guestuser: state.user.guestuser
	};
};

const JitsiLoading = props => {
	return (
		<div className="jitsi-loading-container">
			<div className="jitsi-loading-bg"></div>
			<div className="jitsi-loading-content">
				<h1 className="jitsi-loading-heading">Joining conversation</h1>
				<ReactLoading type={"balls"} color={"#fff"} height={100} width={100} />
			</div>
		</div>
	);
};

const JitsiMeet = props => {
	const { info, elem, guestuser } = props;
	const { content, i } = elem;
	const { first_name, last_name } = info;
	const { slug: roomSlug } = content;
	const { slug: eventSlug } = props.match.params;
	const [loading, status, data] = useFetchMeetInfo(eventSlug, roomSlug);
	const meetName = `${eventSlug}.${roomSlug}`;

	const [onCall, setOnCall] = useState(false);

	const { generate } = useGenerateMultilingualData();

	function handleApi(JitsiMeetAPI) {
		JitsiMeetAPI.addEventListener("readyToClose", () => {
			setOnCall(false);
		});
	}

	if (loading) {
		return (
			<div>
				<LivetoLoader />
			</div>
		);
	}
	if (status) {
		switch (status) {
			case 200: {
				const { jwt, name: fallbackName = "", display_name = {} } = data;
				const name = generate(display_name, fallbackName);
				return onCall ? (
					<Jitsi
						domain={MEET_DOMAIN}
						jwt={jwt}
						roomName={meetName}
						displayName={!guestuser ? first_name + " " + last_name : info.username}
						onAPILoad={JitsiMeetAPI => handleApi(JitsiMeetAPI)}
						containerStyle={{ width: "100%", height: "100%" }}
						loadingComponent={JitsiLoading}
						interfaceConfig={{
							CLOSE_PAGE_GUEST_HINT: false,
							HIDE_INVITE_MORE_HEADER: true,
							SHOW_CHROME_EXTENSION_BANNER: false,
							TOOLBAR_BUTTONS: [
								"microphone",
								"camera",
								"desktop",
								"fullscreen",
								"fodeviceselection",
								"hangup",
								"profile",
								"recording",
								"etherpad",
								"sharedvideo",
								"settings",
								"raisehand",
								"videoquality",
								"filmstrip",
								"stats",
								"shortcuts",
								"tileview",
								"videobackgroundblur",
								"download",
								"help",
								"mute-everyone",
								"security"
							],
							filmStripOnly: false
						}}
						config={{
							defaultLanguage: "en",
							disableInviteFunctions: true,
							enableInsecureRoomNameWarning: false,
							enableClosePage: false,
							startWithAudioMuted: true,
							startWithVideoMuted: true
						}}
					/>
				) : (
					<div className={`jitsi-fallback`}>
						<div className="jitsi-form">
							<h1 className="jitsi-form-heading">{name}</h1>
							<button className="jitsi-form-button" onClick={() => setOnCall(true)}>
								<Localized id="onetoonemeet-join-btn">Join</Localized>
							</button>
						</div>
					</div>
				);
			}
			case 401:
			case 402:
			case 403:
				return (
					<Container>
						<Localized id="onetoonemeet-nopermission">
							You don't have the permission to view this meet
						</Localized>
					</Container>
				);
			case 404:
				return (
					<Container>
						<Localized id="onetoonemeet-meet-notfound">Meet not found</Localized>
					</Container>
				);
			default:
				return (
					<Container>
						<Localized id="onetoonemeet-generic-error">Something went wrong</Localized>
					</Container>
				);
		}
	}
	return (
		<Container>
			<Localized id="onetoonemeet-nomeet-element">No meet element added</Localized>
		</Container>
	);
};

const Container = props => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				position: "relative",
				background: "rgba(255,255,255,.8)",
				color: "#000",
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `url("${jitsi_start_background}")`,
					backgroundPosition: "center",
					backgroundSize: "cover"
				}}
			></div>
			{props.children}
		</div>
	);
};
export default connect(mapStateToProps)(withRouter(JitsiMeet));
