/** @format */

import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "../styles/widgets.scss";
import { makeSubstring } from "../helpers/mainToolbarHelpers";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapStateToProps = state => {
	return {
		polls: state.polls.currentPolls
	};
};

const pollInitial = {
	id: "",
	question: ""
};
function PollWidget(props) {
	const { identifiers, editContent, content, polls, editLocks, editorDispatch, room } = props;
	const edit = value => {
		if (value) {
			const selectedPoll = polls.find(poll => poll.id === value);
			editContent(identifiers, selectedPoll, editorDispatch, room, editLocks);
		} else {
			editContent(identifiers, pollInitial, editorDispatch, room, editLocks);
		}
	};

	const { generate } = useGenerateMultilingualData();

	const pollOptions = polls.map(poll => {
		const { question: fallbackQuestion = "", translated_question = {} } = poll;
		const question = generate(translated_question, fallbackQuestion);
		return (
			<option key={poll.id} value={poll.id}>
				{makeSubstring(question, 0, 40, 40)}
			</option>
		);
	});
	const momentFormatString = "DD.MM.YYYY HH:mm (UTC Z)";
	const disabledStyleOverrides = { textDecoration: "line-through", color: "#848484" };

	return (
		<div className="tool-inner-container">
			POLLS
			<div className="tools">
				<div className="tool">
					Choose a poll
					<select className="widget-select" onChange={e => edit(e.target.value)} value={content.id || ""}>
						<option value="" disabled={true}>
							No selection
						</option>
						{pollOptions}
					</select>
				</div>
				{content !== "" && (
					<>
						<div className="poll-options">
							Poll Options
							<div className="poll-options-container">
								{content.options.map(option => (
									<div className="poll-option">{option.label}</div>
								))}
							</div>
						</div>
						<div className="time-settings">
							<div className="time">
								Starting time
								<strong>{moment(content.start_time).format(momentFormatString)}</strong>
							</div>
							<div className="time">
								Ending time
								<strong>{moment(content.end_time).format(momentFormatString)}</strong>
							</div>
							<div className="time">
								Voting possible until
								<strong>{moment(content.voting_end_time).format(momentFormatString)}</strong>
							</div>
						</div>
						<div>
							Settings
							<div style={!content.show_results ? disabledStyleOverrides : null}>
								Show results to voters
							</div>
							<div style={!content.show_question_before_start ? disabledStyleOverrides : null}>
								Show questions before start time
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default connect(mapStateToProps)(PollWidget);
