/** @format */

import { showNotification } from "@mantine/notifications";

export const notify = (title = "", message = "", options = {}) => {
	showNotification({ title, message, ...options });
};

export const functionHandler = (callback, name = "") => (...args) => {
	try {
		callback(...args);
	} catch (error) {
		console.error(error);
		notify(`Error executing ${name}:`, error.message, { autoClose: false, color: "red" });
	}
};
