/** @format */

import { IconUsers } from "components/icons";
import { IconLock } from "components/icons";
import * as a from "actiontypes";
import { connect } from "react-redux";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Localized } from "@fluent/react";
import { useSearchByProps, useSearchByStringProps } from "./helpers/hooks";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapStateToProps = state => ({
	chatrooms: state.chatrooms,
	connection: state.connection,
	showJoinPrompt: state.view.showJoinPrompt,
	joinPromptRoomId: state.view.joinPromptRoomId
});

const mapDispatchToProps = dispatch => ({
	changeRoom: payload => dispatch({ type: a.WS_CHANGE_ROOM, payload: payload }),
	joinRoom: payload => dispatch({ type: a.WS_JOIN_ROOM, payload: payload }),
	closeModal: () => dispatch({ type: a.WS_CLOSE_PROMPT })
});

function RoomsContent(props) {
	const { chatrooms, connection } = props;
	const { joined_channels, active_channel } = connection;

	const [filteredRooms, searchInput] = useSearchByProps(
		chatrooms,
		["room_name", "topic", "translated_room_name", "translated_topic"],
		"chat-filter-input",
		"Filter chatrooms",
		"chatroom-search",
		{ placeholder: true }
	);

	const { generate } = useGenerateMultilingualData();

	const sortByName = list => {
		return list.sort((a, b) =>
			generate(a.translated_room_name, a.room_name).toLowerCase() >
			generate(b.translated_room_name, b.room_name).toLowerCase()
				? 1
				: -1
		);
	};

	const active_chatroom = chatrooms.find(cr => cr.room_id === active_channel);
	const my_channels = sortByName(
		filteredRooms.filter(cr => joined_channels.includes(cr.room_id)).filter(cr => cr.room_id !== active_channel)
	);
	const other_channels = sortByName(
		filteredRooms.filter(cr => !joined_channels.includes(cr.room_id)).filter(fr => !fr.is_private)
	);

	const has_private_rooms = chatrooms.some(ch => ch.is_private);

	const [joinId, setJoinId] = useState("");

	function submitJoin(e) {
		const roomId = joinId.replace(/\s+/g, "");
		if (e.keyCode === 13 && roomId !== "") {
			props.joinRoom({ room_id: roomId });
		}
	}
	return (
		<div className="rooms-content">
			<Localized id="rooms-heading">
				<div className="rooms-heading">Chatrooms</div>
			</Localized>
			<div className="filter-group">
				<Localized id="chatroom-search">
					<label className="rooms-find-label">Filter by name, company or title</label>
				</Localized>

				{searchInput}
			</div>
			{has_private_rooms && (
				<>
					<Localized id="rooms-search">
						<label className="rooms-find-label">Find room</label>
					</Localized>

					<Localized id="rooms-search" attrs={{ placeholder: true }}>
						<input
							className="rooms-find"
							autoComplete="off"
							placeholder="search for room.."
							value={joinId}
							onChange={e => setJoinId(e.target.value)}
							onKeyDown={e => submitJoin(e)}
						/>
					</Localized>
				</>
			)}

			{active_chatroom && (
				<div className="current-room">
					<Localized id="rooms-sub-heading-active">
						<div className="rooms-sub-heading">Currently discussing in</div>
					</Localized>
					{<Room {...active_chatroom} active />}
				</div>
			)}

			{my_channels.length > 0 && (
				<div className="my-rooms">
					<Localized id="rooms-sub-heading-my-channels">
						<div className="rooms-sub-heading">Other joined rooms</div>
					</Localized>
					{my_channels.map(r => (
						<Room {...r} allowChange changeRoom={props.changeRoom} key={r.room_id} />
					))}
				</div>
			)}

			{other_channels.length > 0 && (
				<div className="other-rooms">
					<Localized id="rooms-sub-heading-other">
						<div className="rooms-sub-heading">Other rooms</div>
					</Localized>

					{other_channels.map(r => {
						if (r.password_protected) {
							return <PasswordProtectedRoom {...r} joinRoom={props.joinRoom} key={r.room_id} />;
						}
						return <Room {...r} allowJoin joinRoom={props.joinRoom} key={r.room_id} />;
					})}
				</div>
			)}

			<RoomJoinModal
				showJoinPrompt={props.showJoinPrompt}
				joinRoom={props.joinRoom}
				closeModal={props.closeModal}
				chatrooms={chatrooms}
				joinPromptRoomId={props.joinPromptRoomId}
			/>
		</div>
	);
}

const Room = props => {
	const {
		room_name: fallbackRoomName = "",
		translated_room_name = {},
		room_id,
		topic: fallbackTopic = "",
		translated_topic = {},
		password_protected,
		allowChange = false,
		allowJoin = false,
		active = false,
		is_private
	} = props;

	const { generate } = useGenerateMultilingualData();
	const room_name = generate(translated_room_name, fallbackRoomName);
	const topic = generate(translated_topic, fallbackTopic);

	function joinRoom() {
		if (!active) {
			if (allowChange) {
				props.changeRoom(room_id);
				//dispatch({ type: a.WS_CHANGE_ROOM, payload: room_id })
			}
			if (allowJoin) {
				props.joinRoom({ room_id: room_id });
				//dispatch({ type: a.WS_JOIN_ROOM, payload: { room_id: room_id } });
			}
		}
	}
	return (
		<div className="room" onClick={e => joinRoom()}>
			<div className={`room-icon${active ? " active" : ""}`}>
				{password_protected ? (
					<IconLock style={{ color: "#fff" }} size="30" />
				) : (
					<IconUsers size="30" style={{ color: "#fff" }} />
				)}
			</div>
			<div className="room-info">
				<div className="room-name">{room_name}</div>
				<div className="room-description">{topic}</div>
				{is_private ? (
					<Localized id="room-status-private">
						<div className="room-status">{"Private"}</div>
					</Localized>
				) : (
					<Localized id="room-status-public">
						<div className="room-status">{"Public"}</div>
					</Localized>
				)}
			</div>
		</div>
	);
};

const PasswordProtectedRoom = props => {
	const { translated_room_name = {}, room_name: fallbackRoomName = "", room_id } = props;

	const { generate } = useGenerateMultilingualData();
	const room_name = generate(translated_room_name, fallbackRoomName);

	const [password, setPassword] = useState("");

	function joinRoom() {
		props.joinRoom({ room_id: room_id, password: password });
	}

	return (
		<div className="room">
			<div className="room-icon">
				<IconLock style={{ color: "#fff" }} size="30" />
			</div>
			<div className="room-info">
				<div className="room-name">{room_name}</div>
				<div className="room-password-input">
					<Localized id="room-join" attrs={{ placeholder: true }}>
						<input
							type="password"
							autoComplete="off"
							placeholder="Password"
							className="room-join-input"
							onChange={e => setPassword(e.target.value)}
						/>
					</Localized>

					<Localized id="room-joinbutton">
						<button className="room-join-button" onClick={() => joinRoom()}>
							Join
						</button>
					</Localized>
				</div>
				<Localized id="room-status-passwordprotected">
					<div className="room-status">{"Password protected"}</div>
				</Localized>
			</div>
		</div>
	);
};

const RoomJoinModal = props => {
	const { chatrooms, joinPromptRoomId, closeModal } = props;
	const roomInfo = chatrooms.find(r => r.room_id === joinPromptRoomId);
	let content = null;
	const [password, setPassword] = useState("");

	function joinRoom() {
		props.joinRoom({ room_id: roomInfo.room_id, password: password });
		setPassword("");
	}

	const { generate } = useGenerateMultilingualData();

	if (roomInfo) {
		const { translated_room_name = {}, room_name: fallbackRoomName = "" } = roomInfo;

		const room_name = generate(translated_room_name, fallbackRoomName);
		content = (
			<>
				<Modal.Header closeButton>
					<Localized id="roomjoinmodal-failed-join" vars={{ roomname: room_name }}>
						<h1 className="heading">{`${room_name} - Failed to join`}</h1>
					</Localized>
				</Modal.Header>

				<Modal.Body>
					<div className="content">
						{roomInfo.password_protected ? (
							<div>
								<Localized id="roomjoinmodal-input-password-again">
									<label className="join-room-label">Please input password again</label>
								</Localized>

								<div className="room-password-input">
									<Localized id="roomjoinmodal-room-join" attrs={{ placeholder: true }}>
										<input
											type="password"
											placeholder="Password"
											className="room-join-input"
											value={password}
											onChange={e => setPassword(e.target.value)}
										/>
									</Localized>

									<Localized id="roomjoinmodal-join-room-button">
										<button className="room-join-button" onClick={() => joinRoom()}>
											submit
										</button>
									</Localized>
								</div>
							</div>
						) : (
							<Modal.Footer>
								<Localized id="roomjoinmodal-join-proceed-button">
									<button onClick={() => closeModal()}>Proceed</button>
								</Localized>
							</Modal.Footer>
						)}
					</div>
				</Modal.Body>
			</>
		);
	} else {
		content = (
			<>
				<Modal.Header closeButton>
					<Localized id="roomjoinmodal-no-room-info-heading">
						<h1 className="heading">Room join failed</h1>
					</Localized>
				</Modal.Header>
				<Modal.Body>
					<Localized id="roomjoinmodal-no-room-info-error">
						<div className="content">
							You tried to join a room that does not exist. Check that your room join information is
							correct, and try again.
						</div>
					</Localized>
				</Modal.Body>
				<Modal.Footer>
					<Localized id="roomjoinmodal-join-proceed-button">
						<button onClick={() => closeModal()}>Proceed</button>
					</Localized>
				</Modal.Footer>
			</>
		);
	}
	return (
		<Modal contentClassName="join-room-modal" centered show={props.showJoinPrompt} onHide={() => closeModal()}>
			{content}
		</Modal>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomsContent);
