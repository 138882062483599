/** @format */

import { Menu, Item, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import * as a from "actiontypes";
import { connect } from "react-redux";
import { MessageWrapper } from "./message";
import { parseImageLetter } from "./message";
import { Localized } from "@fluent/react";
import { useGenerateMultilingualData } from "containers/VirtualEvent/performers/helpers";

const mapStateToProps = state => ({
	active_channel: state.connection.active_channel,
	chatrooms: state.chatrooms,
	is_moderator: state.websocketUser.is_moderator
});
const mapDispatchToProps = dispatch => ({
	reactToMessage: payload => dispatch({ type: a.WS_REACT_WITH_EMOJI, payload: payload }),
	deleteAllUserMessages: payload => dispatch({ type: a.WS_DELETE_ALL_USER_MESSAGES, payload: payload }),
	deleteMessage: payload => dispatch({ type: a.WS_DELETE_MESSAGE, payload: payload }),
	silenceUser: payload => dispatch({ type: a.WS_SILENCE_USER, payload: payload })
});

const MessageMenu = props => {
	const { message_id, icon, sender_name, message, room_id, is_moderator, user_id } = props;
	const translated_room_name = props.chatrooms.find(cr => cr.room_id === props.active_channel).translated_room_name;
	const fallbackRoomName = props.chatrooms.find(cr => cr.room_id === props.active_channel).room_name;

	const { generate } = useGenerateMultilingualData();

	const active_room_name = generate(translated_room_name, fallbackRoomName);

	function deleteAllMessages() {
		props.deleteAllUserMessages({ user_id: user_id, room_id: room_id });
	}

	function deleteSingleMessage() {
		props.deleteMessage({ message_id: message_id, room_id: room_id });
	}

	function muteUser(time) {
		props.silenceUser({ user_id: user_id, time: time, reason: "" });
	}
	return (
		<Menu id={message_id} className="message-moderator-menu">
			{is_moderator && (
				<>
					<div className="moderator-user-preview">
						<div className="moderator-user-preview-image" style={{ backgroundImage: `url("${icon}")` }}>
							{parseImageLetter(icon, sender_name)}
						</div>
						<div className="moderator-user-preview-info">
							<div className="moderator-user-preview-name">{sender_name}</div>
							<small className="moderator-user-preview-message">
								<MessageWrapper message={message} />
							</small>
						</div>
					</div>
					<Separator />
					<Item onClick={() => deleteSingleMessage()}>
						<Localized id="moderator-delete-message">Delete message</Localized>
					</Item>
					<Item onClick={() => deleteAllMessages()}>
						<Localized id="moderator-delete-all-messages" vars={{ room: active_room_name }}>
							{"Delete all user messages on {$room}"}
						</Localized>
					</Item>
					<Separator />
					<Item className="muting">
						<div className="muting-user">
							<Localized id="moderator-mute-user" vars={{ user: sender_name }}>
								<b>{`Mute {$user} for:`}</b>
							</Localized>
							<div className="mute-options">
								<Localized id="moderator-mute-minutes" vars={{ minute: 5 }}>
									<button className="mute-option" onClick={() => muteUser(300)}>
										5minutes
									</button>
								</Localized>
								<Localized id="moderator-mute-minutes" vars={{ minute: 15 }}>
									<button className="mute-option" onClick={() => muteUser(900)}>
										15minutes
									</button>
								</Localized>
								<Localized id="moderator-mute-minutes" vars={{ minute: 30 }}>
									<button className="mute-option" onClick={() => muteUser(1800)}>
										30minutes
									</button>
								</Localized>
								<Localized id="moderator-mute-minutes" vars={{ minute: 60 }}>
									<button className="mute-option" onClick={() => muteUser(3600)}>
										60minutes
									</button>
								</Localized>
							</div>
						</div>
					</Item>
				</>
			)}
		</Menu>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageMenu);
