/** @format */

import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useFetchStreams } from "../apiCalls";
import "../styles/widgets.scss";
import { generateEditorLocalizedField } from "../addModal";

const mapStateToProps = state => {
	return {
		organizer: state.event.organizer.slug,
		languages: state.languages
	};
};

const streamInitial = {
	slug: "",
	name: "",
	start_time: "",
	end_time: "",
	source: "",
	url: ""
};

function StreamWidget(props) {
	const { identifiers, editContent, content, editLocks, editorDispatch, room, languages = [] } = props;
	const { slug } = useParams();

	const streams = useFetchStreams(props.organizer, slug);

	const edit = value => {
		if (value === "") {
			editContent(identifiers, streamInitial, editorDispatch, room, editLocks);
		} else {
			const selectedStream = streams.find(s => s.slug === value);
			editContent(identifiers, selectedStream, editorDispatch, room, editLocks);
		}
	};

	const streamOptions = streams.map(stream => {
		const { name = "", display_name = {} } = stream;
		return <option value={stream.slug}>{generateEditorLocalizedField(display_name, name, languages)}</option>;
	});

	return (
		<div className="tool-inner-container">
			STREAM
			<div className="tools">
				<div className="tool">
					Choose stream
					<select onChange={e => edit(e.target.value)} value={content.slug}>
						<option value={""}>No stream</option>
						{streamOptions}
					</select>
				</div>
			</div>
		</div>
	);
}

export default connect(mapStateToProps)(StreamWidget);
